<template>
  <form class="form" novalidate="novalidate" id="st_urbanism_regulations_form">
    <portal to="urbanism-regulation-modal-title">
      <h5 class="modal-title">{{ headerText }}</h5>
    </portal>
    <div class="form-group">
      <label> {{ fields.content.label }}</label>
      <b-textarea
        rows="30"
        :ref="fields.content.name"
        :name="fields.content.name"
        v-model="model[fields.content.name]"
       />
    </div>
    <div class="form-actions d-flex justify-content-between">
      <st-button size="large" variant="link" :callback="hide">
        {{ $t("GENERAL.BUTTON.CANCEL") }}
      </st-button>
      <st-button size="large" variant="primary" :callback="doSubmit">
        <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
      </st-button>
    </div>
  </form>
</template>
<script>
import { mapActions } from 'vuex';
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { UrbanismRegulationsModel } from "@/modules/administrator/models/urbanism-regulations-model";
const { fields } = UrbanismRegulationsModel;

const formSchema = new FormSchema([
  fields.id,
  fields.zone_key,
  fields.category_key,
  fields.tenant_name,
  fields.content,
]);

export default {
  name: "UrbanismRegulationsForm",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      fv: null,
      fields: fields,
    };
  },
  computed: {
    headerText() {
      return this.$t('URBANISM_REGULATIONS.EDIT_ITEM_FORM', [this.item.zone_key, this.item.category_key]);
    },
  },
  methods: {
    ...mapActions({
      doUpdate: "administrator/urbanismRegulationZonesForm/updateItem",
    }),
    async doSubmit() {
      const validate = await this.fv.validate();
      if (validate === "Valid" && this.model.content) {
        const { id, ...values } = formSchema.cast(this.model);
        this.doUpdate({ id, values }).then(() => this.hide());
      }
    },
    hide() {
      this.$emit("cancelForm");
    },
  },
  created() {
    this.model = formSchema.initialValues(this.item);
  },
  mounted() {
    this.fv = createFormValidation("st_urbanism_regulations_form", this.rules);
  },
};
</script>
<style lang="scss" scoped>
.form {
    height: inherit;
    display: flex;
    flex-direction: column;
    .form-actions {
        margin-top: auto;
    }
}
</style>