<template>
    <form class="form"
            novalidate="novalidate"
            id="st_urbanism_regulation_zones_form"
    >
        <div class="row pb-6">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ fields.name.label }} *
                    </label>
                    <st-input-text
                        :ref="fields.name.name"
                        :name="fields.name.name"
                        v-model="model[fields.name.name]"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.type.label }} *
                    </label>
                    <div>
                        <st-select
                            v-model="model[fields.type.name]"
                            :ref="fields.type.name"
                            :field="fields.type"
                            :formValidation="fv"
                        >
                        </st-select>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.tenant_id.label }} *
                    </label>
                    <div>
                        <st-select
                            v-model="model[fields.tenant_id.name]"
                            :ref="fields.tenant_id.name"
                            :field="fields.tenant_id"
                            :formValidation="fv"
                            searchable
                        >
                        </st-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <st-button
                size="large"
                variant="link"
                :callback="hide"
            > {{ $t('GENERAL.BUTTON.CANCEL') }}
            </st-button>
            <st-button
                size="large"
                variant="primary"
                :callback="doSubmit"
            >
                <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
            </st-button>
        </div>
    </form>
</template>

<script>

import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { UrbanismRegulationZonesModel } from '@/modules/administrator/models/urbanism-regulation-zones-model';

const { fields } = UrbanismRegulationZonesModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.type,
    fields.tenant_id,
]);

import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ManageUrbanismRegulationZonesForm',
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            record: 'administrator/urbanismRegulationZonesForm/record',
        }),
        fields() {
            return fields;
        }
    },
    methods: {
        ...mapActions({
            doCreate: 'administrator/urbanismRegulationZonesForm/create',
            doUpdate: 'administrator/urbanismRegulationZonesForm/update',
            doFetch: 'administrator/urbanismRegulationZonesList/doFetch',
        }),
        hide() {
            this.$emit('cancelForm');
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const {id, ...values} = formSchema.cast(this.model);
                values.tenant_name = this.fields.tenant_id.options.find((el) => el.value === this.model.tenant_id)?.text;
                if (this.editMode) {
                    this.doUpdate({id, values}).then(() => {
                        this.$emit('submit');
                        this.hide()
                    });
                } else {
                    this.doCreate(values).then(() => {
                        this.$emit('submit');
                        this.hide();
                    });
                }
            }
        }
    },
    created() {
        this.model = formSchema.initialValues(this.record);
    },
    mounted() {
        this.fv = createFormValidation('st_urbanism_regulation_zones_form', this.rules);
    },
};
</script>
