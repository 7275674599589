import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class DocumentSeriesTypeField {

    static relationToOne(name, label, rules) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('document-series-types', {
                        params: {
                            fields: 'name,id',
                            limit: 100,
                        },
                    })
                    .then(({ data }) => {
                        resolve(data.data);
                    });
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
        )
    }
}
