import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
import DateField from '@/shared/fields/date-field';
import { DecisionTargetField } from '@/modules/decision-target/decision-target-field';
import { DecisionPurposeField } from '@/modules/decision-purpose/decision-purpose-field';
import { CountyField } from '@/shared/common-fields/county-field';

const field = (name) => i18n.t(`DECISION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DECISION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        { placeholder: placeholder('name')},
    ),
    description: new StringField(
        'description',
        field('description'),
    ),
    number: new IntegerField(
        'number',
        field('number'),
        { required: true },
    ),
    entry_date: new DateField(
        'entry_date',
        field('entry_date'),
        { required: true },
    ),
    expiration_date: new DateField(
        'expiration_date',
        field('expiration_date'),
    ),
    county_id: CountyField.relationToOne(
        'county_id',
        field('county'),
    ),
    target_id: DecisionTargetField.relationToOne(
        'target_id',
        field('target_id'),
        { autocompleteRequired: true, firstLetterFilter: true}
    ),
    purpose_id: DecisionPurposeField.relationToOne(
        'purpose_id',
        field('purpose_id'),
        { autocompleteRequired: true},
    ),
    key: new StringField(
        'key',
        field('key'),
    ),
};

export class DecisionModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
