<template>
    <div>
        <form class="form"
            novalidate
            id="st-document-series-form"
        >
            <div class="form-group">
                <label>
                    {{ fields.series_type.label }} *
                </label>
                <st-select
                    v-model="model[fields.series_type.name]"
                    :ref="fields.series_type.name"
                    :field="fields.series_type"
                    :formValidation="fv"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    :name="fields.name.name"
                    v-model="model[fields.name.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.description.label }} *
                </label>
                <st-input-text
                    :name="fields.description.name"
                    v-model="model[fields.description.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.prefix.label }} *
                </label>
                <st-input-text
                    :name="fields.prefix.name"
                    v-model="model[fields.prefix.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.rejected_prefix.label }} *
                </label>
                <st-input-text
                    :name="fields.rejected_prefix.name"
                    v-model="model[fields.rejected_prefix.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.separator.label }} *
                </label>
                <st-select
                    v-model="model[fields.separator.name]"
                    :ref="fields.separator.name"
                    :field="fields.separator"
                    :formValidation="fv"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.current_index.label }} *
                </label>
                <b-form-input 
                    :name="fields.current_index.name"
                    v-model.number="model[fields.current_index.name]"
                    v-positiveIntegerOnly
                />
            </div>

            <div>
                <b-form-checkbox
                    size="lg"
                    v-model="model[fields.yearly_reset.name]"
                    :value="true"
                    :unchecked-value="false"
                >
                    {{ fields.yearly_reset.label }}
                </b-form-checkbox>
            </div>

             <div>
                <b-form-checkbox
                    size="lg"
                    v-model="model[fields.active.name]"
                    :value="true"
                    :unchecked-value="false"
                >
                    {{ fields.active.label }}
                </b-form-checkbox>
            </div>
        </form>

        <div class="pt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel"/>
        </div>
    </div>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { FormSchema } from '@/shared/form/form-schema';
import { DocumentSeriesModel } from './models/document-series-model';

const { fields } = DocumentSeriesModel;

const formSchema = new FormSchema([
    fields.series_type,
    fields.name,
    fields.description,
    fields.prefix,
    fields.rejected_prefix,
    fields.separator,
    fields.current_index,
    fields.yearly_reset,
    fields.active,
]);

export default {
    name: 'DocumentSeriesForm',
    props: {
        series: {
            type: Object,
            default: () => ({
                current_index: 1,
            }),
        },
    },
    data() {
        return {
            model: formSchema.initialValues(this.series),
            fields,
            rules: formSchema.rules(),
            fv: null,
        }
    },
    mounted() {
        this.fv = createFormValidation('st-document-series-form', this.rules);
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                this.$emit('submit', cast);
            }
        },
        cancel() {
            this.$emit('cancel');
        },
    },
}
</script>