<template>
    <form class="form"
        novalidate="novalidate"
        id="st_account_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label>
                {{ $t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.FORM.NAME') }} *
            </label>
            <st-input-text
                v-model="account.name"
                ref="name"
                name="name"
            />
        </div>
        <div class="form-group">
            <label>
                {{ $t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.FORM.BANK') }} *
            </label>
            <st-input-text
                v-model="account.bank"
                ref="bank"
                name="bank"
            />
        </div>
        <div class="form-group">
            <label>
                {{ $t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.FORM.IBAN') }} *
            </label>
            <st-input-text
                v-model="account.iban"
                ref="iban"
                name="iban"
            ></st-input-text>
        </div>
        <div class="mb-6">
            <label>
                {{ $t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.FORM.STATUS') }}
            </label>
            <b-form-radio-group
                size="lg"
                v-model="account.status"
                :options="statusOptions"
                name="institution-status"
            ></b-form-radio-group>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { CollectorAccountModel } from '@/modules/administrator/models/collector-account-model';

const { fields } = CollectorAccountModel;
const formSchema = new FormSchema([
    fields.name,
    fields.bank,
    fields.iban,
])

import Message from '@/shared/message/message';
import { mapActions } from 'vuex';
export default {
    name: 'ManageAccountForm',
    props: {
        selectedAccount: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            account: {
                name: '',
                bank: '',
                iban: '',
                status: 'active',
            },
            statusOptions: [
                { text: this.$t('COLLECTOR_ACCOUNTS.STATUS_TYPE.ACTIVE'), value: 'active' },
                { text: this.$t('COLLECTOR_ACCOUNTS.STATUS_TYPE.INACTIVE'), value: 'inactive' },
            ],
        };
    },
    mounted() {
        if(this.editMode) {
            this.account = { ...this.selectedAccount };
        }
        this.fv = createFormValidation('st_account_form', this.rules);
    },
    methods: {
        ...mapActions({
            addAccount: 'administrator/addAccount',
            updateAccount: 'administrator/updateAccount',
        }),
        clearModal() {
            this.account = {
                name: '',
                bank: '',
                iban: '',
                status: 'active',
            }
        },
        hide() {
            this.$emit('cancelForm');
            this.clearModal();
        },
        manageAccount() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.editMode) {
                       this.onEditAccount();
                    } else {
                        this.onAddAccount();
                    }
                }
            });
        },
        onAddAccount() {
            this.addAccount({orgId: this.$route.query.id, account: this.account})
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('COLLECTOR_ACCOUNTS.SUCCESS.ADD');
            })
        },
        onEditAccount(){
            const account = {
                name: this.account.name,
                bank: this.account.bank,
                iban: this.account.iban,
                status: this.account.status,
            }

            this.updateAccount({accountId: this.account.id, account})
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('COLLECTOR_ACCOUNTS.SUCCESS.UPDATE');
            })
        }
    }
};
</script>
