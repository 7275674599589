import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import ComputedField from '@/shared/fields/computed-field';
import { DepartmentsField } from '@/modules/administrator/fields/departments-field';
import { FunctionsField } from '@/modules/administrator/fields/functions-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';

const field = (name) => i18n.t(`FUNCTIONARY.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`FUNCTIONARY.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`FUNCTIONARY.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    first_name: new StringField(
        'first_name',
        field('first_name'),
        { placeholder: placeholder('first_name')},
    ),
    last_name: new StringField(
        'last_name',
        field('last_name'),
        { placeholder: placeholder('last_name')},
    ),
    // Table
    full_name: new ComputedField('full_name', field('full_name'),
        (record) => {
            if (!record) return '';
            const { first_name, last_name } = record;
            return `${first_name} ${last_name}`;
        },
        { placeholder: placeholder('full_name') }
    ),
    email: new StringField(
        'user_email',
        field('email'),
        { placeholder: placeholder('email')},
    ),
    status: new EnumField('status', field('status'),
        [
            { value: 'pending',  text: enumLabel('status', 'pending'), type: 'primary', name: 'pending' },
            { value: 'active', name: 'active', text: enumLabel('status', 'active'), type: 'success' },
            { value: 'inactive', name: 'inactive', text: enumLabel('status', 'inactive'), type: 'danger' },
        ],
        {},
        { placeholder: placeholder('status') },
    ),
    departments: new ComputedField('departments', field('departments'),
        (record) => {
            if (!record?.departments) return '';
            return record.departments.join(', ');
        },
        { placeholder: placeholder('departments') }
    ),
    functions: new ComputedField('functions', field('functions'),
        (record) => {
            if (!record?.functions) return '';
            return record.functions.join(', ');
        },
        { placeholder: placeholder('functions') }
    ),
    account_type: new ComputedField('account_type', field('account_type'),
        (record) => {
            if (!record?.user_type_name) return '';
            const hasUserTypeName = ['admin', 'staff'].includes(record.user_type_name);
            return hasUserTypeName ? field(record?.user_type_name) : '';
        },
    ),
    // Filter
    selectedOrganisation: OrganizationField.relationToOne(
        'organisations',
        field('organisations'),
        {},
        { placeholder: placeholder('organisations') },
    ),
    selectedDepartment: DepartmentsField.relationToOne(
        'departments',
        field('departments'),
        {},
        { placeholder: placeholder('departments'), type: 'contains', searchByText: true },
        [{
            key: 'org_id',
            name: 'organisations'
        }],
        { asyncData: true }
    ),
    selectedFunction: FunctionsField.relationToOne(
        'functions',
        field('functions'),
        {},
        { placeholder: placeholder('functions'), type: 'contains', searchByText: true },
        [{
            key: 'dep_id',
            name: 'departments'
        }],
        { asyncData: true }
    )
};

export class FunctionaryModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
