<template>
<st-page
    layout="tab"
    :showHeader="false"
>
    <div class="row p-8">
        <div class="col-12 col-md-6">
            <form class="form"
                novalidate="novalidate"
                id="st_edit_institution_form"
                @submit.stop.prevent="onSubmit()"
            >
                <div class="form-group">
                    <label>
                        {{ fields.name.label }} *
                    </label>
                    <st-input-text
                        v-model="model[fields.name.name]"
                        :ref="fields.name.name"
                        :name="fields.name.name"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.type.label }}*
                    </label>
                    <b-form-select
                        v-model="model[fields.type.name]"
                        :options="fields.type.options"
                        value-field="value"
                        text-field="name"
                        :ref="fields.type.name"
                        :name="fields.type.name"
                        @change="onChange($event, fields.type)"
                    ></b-form-select>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.description.label }}
                    </label>
                    <b-form-textarea
                         v-model="model[fields.description.name]"
                        :ref="fields.description.name"
                        :name="fields.description.name"
                        id="textarea-description"
                        :placeholder="fields.description.placeholder"
                    ></b-form-textarea>
                </div>
                <div v-show="model[fields.type.name]" class="form-group">
                    <label v-show="model[fields.type.name] === 'county_council'">
                        {{ $t('INSTITUTION.FIELDS.UAT') }} *
                    </label>
                    <label v-show="isCityHallOrServices">
                        {{ fields.county_id.label }} *
                    </label>
                    <st-autocomplete
                        v-model="model[fields.county_id.name]"
                        :options="fields.county_id.options"
                        :ref="fields.county_id.name"
                        :name="fields.county_id.name"
                        :disabled="true"
                        :model="model"
                        :formValidation="fv"
                        :fields="fields"
                    ></st-autocomplete>
                </div>
                <div  v-show="isCityHallOrServices" class="form-group">
                    <label v-if="model[fields.type.name] === 'services'">
                        {{ $t('INSTITUTION.FIELDS.UAT') }}
                    </label>
                    <label v-else>
                        {{ $t('INSTITUTION.FIELDS.UAT') }} *
                    </label>
                    <st-autocomplete
                        v-model="model[fields.locality_id.name]"
                        :options="fields.locality_id.options"
                        :ref="fields.locality_id.name"
                        :name="fields.locality_id.name"
                        :disabled="!isCityHallOrServices"
                        :model="model"
                        :formValidation="fv"
                        :fields="fields"
                    ></st-autocomplete>
                </div>
                <div class="mb-6">
                    <label>
                        {{ fields.status.label }}
                    </label>
                    <b-form-radio-group
                        size="lg"
                        v-model="model[fields.status.name]"
                        :name="fields.status.name"
                        :options="fields.status.options"
                    ></b-form-radio-group>
                </div>
                <hr class="pt-10"/>
                <div>
                    <b-form-checkbox
                        size="lg"
                        id="test_institution"
                        v-model="model[fields.test_org.name]"
                        :name="fields.test_org.name"
                        :value="true"
                        :unchecked-value="false"
                    >
                    {{ fields.is_test.placeholder }}
                    </b-form-checkbox>
                </div>
            </form>
            <div class="d-flex justify-content-between w-100 mt-10">
                <st-button
                    variant="link"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="discard"
                >
                    {{ $t('INSTITUTION.BUTTON.CANCEL') }}
                </st-button>
                <st-button
                    variant="primary"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="save"
                >
                    <span> {{ $t('INSTITUTION.BUTTON.SAVE') }}</span>
                </st-button>
            </div>
        </div>
    </div>
</st-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Message from '@/shared/message/message';
import { OrganisationModel } from "@/modules/administrator/models/organisation-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = OrganisationModel;

const formSchema = new FormSchema([
    fields.name,
    fields.description,
    fields.county_id,
    fields.locality_id,
    fields.status,
    fields.test_org,
    fields.type,
]);

export default {
    name: 'EditInstitution',
    props: {
        selectedInstitution: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            fields,
            model: formSchema.initialValues(this.selectedInstitution),
            rules: formSchema.rules(),
            fv: null
        };
    },
    mounted() {
        this.fv = createFormValidation('st_edit_institution_form', this.rules);
        this.fv.disableValidator(fields.locality_id.name);
    },
    computed: {
        ...mapGetters({
            institutionAPI: 'administrator/institution',
        }),
        isCityHallOrServices() {
            return this.model.type === 'city_hall'
                || this.model.type === 'services';
        },
    },
    watch: {
        'model.type': {
            handler() {
                if (this.model.type === 'city_hall') {
                    this.fv.enableValidator(fields.locality_id.name);
                } else {
                    this.fv.disableValidator(fields.locality_id.name);
                }
            },
        },
    },
    methods: {
        ...mapActions({
            updateInstitution: 'administrator/orgForm/updateInstitution',
            getInstitution: 'administrator/getInstitution',
        }),
        discard() {
            this.$router.back();
        },
        save() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    const counties = this.fields.county_id.options;
                    const selectedCounty = counties.find( county => county.value === this.model.county_id);

                    const localities = this.fields.locality_id.options;
                    const selectedLocality = localities.find( locality => locality.value === this.model.locality_id);

                    const payload = {
                        ...this.model,
                        county_name: selectedCounty.text,
                        locality_name: selectedLocality?.text,
                    }

                    this.updateInstitution({id: this.selectedInstitution.id, payload })
                    .then(() => {
                        this.$emit('updateInstitutions', this.model);
                        this.fv.resetForm();
                        Message.success('INSTITUTION.SUCCESS.UPDATE_ORG');
                    })
                }
            })
        },
        onChange(value, field) {
            this.fv.revalidateField(field.name, true);
            if (field?.children) {
                field.children.forEach((child) => {
                    const el = this.fields[child.name];
                    el.fetchParams = { [child.key] : value };
                    this.model[child.name] = '';
                    el.updateOptions();
                    if (this.fv.fields[child.name]) {
                        this.fv.revalidateField(child.name, true);
                    }
                });
            }
        }
    }
};
</script>

