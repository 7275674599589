<template>
  <div>
    <portal to="urbanism-regulation-modal-title">
        <h5 class="modal-title">{{ headerText }}</h5>
    </portal>
    <div class="row pb-6" v-if="items.length">
      <div v-for="item in items" :key="item.id" class="col col-4">
        <b-card class="st-section mt-6">
          <div class="st-section-header">
            <div class="st-section-header-title">
              {{ item.category_key }}
            </div>
            <div class="st-section-header-actions">
              <st-button
                variant="link"
                size="sm"
                :callback="edit" 
                :callbackParams="item">
                <i class="fas fa-pencil-alt" />
              </st-button>
            </div>
          </div>
          <div class="st-section-body">
            <div class="font-weight-bold">{{ $t('URBANISM_REGULATIONS.ITEM_DETAILS') }}:</div>
            <div class="item-content mt-2">{{ item.content }}</div>
          </div>
        </b-card>
      </div>
    </div>
    <div
      v-if="!items.length && !isLoading" 
      class="urbanism-regulation-panel-empty">
      {{ $t('URBANISM_REGULATIONS.EMPTY_ITEMS_LABEL') }}
    </div>
    <div class="d-flex justify-content-between">
      <st-button size="large" variant="link" :callback="hide">
        {{ $t("GENERAL.BUTTON.CANCEL") }}
      </st-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UrbanismRegulationsPanel",
  data() {
    return {
      items: []
    };
  },
  computed: {
    ...mapGetters({
      record: "administrator/urbanismRegulationZonesForm/record",
      loading: 'shared/loading',
    }),
    fields() {
      return fields;
    },
    headerText() {
      return this.$t('URBANISM_REGULATIONS.TITLE', [this.record?.key]);
    },
    isLoading() {
      return this.loading['urbanismRegulations/getItems'];
    },
  },
  methods: {
    ...mapActions({
      fetchData: "administrator/urbanismRegulationZonesForm/get",
    }),
    hide() {
      this.$emit("cancel");
    },
    edit(item) {
      this.$emit('edit', item);
    },
  },
  async created() {
    if (this.record?.id) {
      this.items = await this.fetchData(this.record.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.st-section {
  height: 330px;
}
.item-content {
  max-height: 200px;  
  text-overflow: ellipsis;
  overflow-y: hidden;
}
.urbanism-regulation-panel-empty {
  height: 710px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>