<template>
    <div>
        <st-modal
            :id="modalId"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="xl"
            customClass="form-modal"
            ref="modal"
        >
            <template #header>
                <portal-target name="urbanism-regulation-modal-title"></portal-target>
            </template>
            <template #body>
                <div class="urbanism-regulation-modal-content">
                    <UrbanismRegulationsPanel
                        ref="urbanismRegulationsPanel"
                        v-if="isVisible"
                        @edit="edit"
                        @cancel="hide"
                    ></UrbanismRegulationsPanel>
                    <UrbanismRegulationsForm
                        ref="urbanismRegulationsForm"
                        v-if="editMode"
                        :item="selected"
                        @cancelForm="closeForm"
                    ></UrbanismRegulationsForm>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import UrbanismRegulationsPanel from './UrbanismRegulationsPanel.vue';
import UrbanismRegulationsForm from './UrbanismRegulationsForm.vue';

export default {
    name: 'ManageUrbanismRegulationZonesModal',
    components: { 
        UrbanismRegulationsPanel,
        UrbanismRegulationsForm,
    },
    data() {
        return {
            modalId: `st-modal-urbanism-regulations-${generateRandomId()}`,
            isVisible: false,
            editMode: false,
            selected: {},
            items: []
        }
    },
    computed: {
        ...mapGetters({
            record: "administrator/urbanismRegulationZonesForm/record",
        }),
    },
    methods: {
        ...mapActions({
            unselectRecord: 'administrator/urbanismRegulationZonesForm/unselect',
            fetchData: "administrator/urbanismRegulationZonesForm/get",
        }),
        show() {
            this.isVisible = true;
            this.editMode = false;
            this.$refs['modal'].show();
        },
        hide() {
            this.isVisible = false;
            this.unselectRecord();
            this.$refs['modal'].hide();
        },
        edit(item) {
            this.selected = item;
            this.isVisible = false;
            this.editMode = true;
        },
        closeForm() {
            this.editMode = false;
            this.isVisible = true;
            this.selected = {};
        }
    },
};
</script>
<style lang="scss">
    .urbanism-regulation-modal-content {
        height: 750px;
    }
</style>
