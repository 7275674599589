import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';

const field = (name) => i18n.t(`DEPARTMENTS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`DEPARTMENTS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DEPARTMENTS.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        { placeholder: placeholder('name')},
    ),
    status: new EnumField(
        'status',
        field('status'),
        [
            { value: 'active', text: enumLabel('status', 'active'), type: 'primary', name: 'active' },
            { value: 'inactive', text: enumLabel('status', 'inactive'), type: 'danger' , name: 'inactive'},
        ],
        {},
        { placeholder: placeholder('status')},
    ),
};

export class DepartmentModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
