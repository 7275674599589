<template>
    <div>
        <st-data-table
            :per-page="extendedRows.length"
            :items="extendedRows"
            :fields="fields"
            :actions="actions"
            :loading="isLoading"
            :modelPresenter="presenter"
            @edit="editDecision"
            @delete="deleteDecision"
            @extend="addExtension"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { DecisionModel } from '@/modules/administrator/models/decision-model';

    const { fields } = DecisionModel;

    export default {
        name: 'DecisionListTable',
        components: {
        },
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                presenter: DecisionModel.presenter,
                selectedEntity: {},
                selectedDecision: {},
            };
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                rows: 'administrator/decisionList/rows',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            isLoading () {
                return this.loading['admin/getDecisions'] ;
            },
            fields() {
                return [
                    fields.name,
                    fields.purpose_id,
                    fields.key,
                    fields.entry_date,
                    fields.expiration_date,
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEditDecision) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        customIcon: false,
                        type: 'primary',
                        tooltipText: this.$t('DECISION.LIST.EDIT'),
                    });
                }
                if (this.orgPermissions.hasPermissionToDestroyDecision) {
                    actions.push({
                        name: 'delete',
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger',
                        tooltipText: this.$t('DECISION.LIST.DELETE'),
                    });
                }
                actions.push({
                    name: 'extend',
                    icon: 'plus-circle',
                    customIcon: false,
                    type: 'primary',
                    tooltipText: this.$t('DECISION.LIST.ADD_EXTENSION'),
                    hideOnRow: true,
                });
                return actions
            },
            extendedRows() {
                const rows = [];
                this.rows.forEach((row) => {
                    const { extensions, ...decision } = row;
                    rows.push(decision);
                    if (extensions?.length) {
                        const updatedExtensions = extensions.map((el) => ({
                            ...el,
                            name: `Prelungire ${el.name}`,
                            hideAction: el.is_extension
                        }));
                        rows.push(...updatedExtensions);
                    }
                });
                return rows;
            }
        },
        methods: {
            ...mapActions({
                deleteRecord: 'administrator/decisionForm/deleteDecision',
            }),
            editDecision(data) {
                this.$emit('editDecision', data);
            },
            async deleteDecision(data) {
                await this.deleteRecord(data.item.id);
                this.$emit('updateDecision');
            },
            addExtension(data) {
                this.$emit('addExtension', data);
            }
        },
    };
</script>
