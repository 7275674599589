import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`URBANISM_REGULATION_ZONES.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`URBANISM_REGULATION_ZONES.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`URBANISM_REGULATION_ZONES.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }, { placeholder: placeholder('name') }),
    key: new StringField('key', label('key'), {
        required: true,
    }, { placeholder: placeholder('key') }),
    tenant_id: TerritorialAdministrativeUnitField.relationToOne(
        'tenant_id',
        label('tenant'),
        { autocompleteRequired: true },
        {
            placeholder: placeholder('tenant') ,
            type: 'autocomplete',
        },
    ),
    tenant_name: new StringField('tenant_name', label('tenant'), {}, {}),
    type: new EnumField('type', label('type'), [
        { value: 'PUG', text: enumLabel('type', 'PUG') },
        { value: 'PUZ', text: enumLabel('type', 'PUZ') },
        { value: 'PUD', text: enumLabel('type', 'PUD') }
    ], {
        autocompleteRequired: true
    }, { placeholder: placeholder('type') }),
};

export class UrbanismRegulationZonesModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
