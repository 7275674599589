<template>
    <div>
        <!-- filtering section -->
        <div class="d-flex justify-content-between my-4">
            <div class="d-flex align-items-center">
                <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="searchByNameTerm"
                        type="search"
                        :placeholder="$t('ADMIN_USERS_MODAL.PLACEHOLDER')"
                    ></b-form-input>

                    <b-input-group-append>
                        <b-button :disabled="!searchByNameTerm"> {{ $t('GENERAL.BUTTON.ERASE') }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

        </div>

        <!-- actual table with data -->
        <st-data-table
            :items="users"
            :fields="fields"
            :loading="isLoading"
            :modelPresenter="presenter"
            responsive="sm"
            hover
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :selectable="selectableRow"
            select-mode="single"
            @onRowSelected="onRowSelected"
        >
        </st-data-table>

        <!-- paginator -->
        <div v-if="!isLoading" class="d-flex justify-content-end mt-6">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="mr-10"
            ></b-pagination>
        </div>

        <!-- modal buttons -->
        <div class="pt-6">
            <slot name="buttons" :cancel="cancel"/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { FunctionaryModel } from '@/modules/administrator/models/functionary-model';
import { FETCH_DEPARTMENT_USERS } from '@/modules/administrator/store/department-users-store';
import { FETCH_FUNCTION_USERS } from '@/modules/administrator/store/function-users-store';

const {fields} = FunctionaryModel;

export default {
    name: 'UsersListTable',
    props: {
        selectedEntity: {
            type: Object,
            required: true
        },
        institution: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            presenter: FunctionaryModel.presenter,
            sortBy: 'name',
            sortDesc: false,
            searchByNameTerm: undefined,
            perPage: 10,
            selectableRow: false,
            currentPage: 1
        }
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            departmentUsers: 'administrator/departmentUsers',
            functionUsers: 'administrator/functionUsers',
        }),
        isLoading() {
            return this.loading['admin/getUsers'] ||
                this.loading['admin/getDepartmentUsers'] ||
                this.loading[`admin/functionUsers/${FETCH_FUNCTION_USERS}`] ||
                this.loading[`admin/functionUsers/${FETCH_DEPARTMENT_USERS}`]
        },
        users() {
            if (this.selectedEntity.type === 'department') return this.departmentUsers.rows;
            if (this.selectedEntity.type === 'function') return this.functionUsers.rows;
            return []
        },
        fields() {
            return [
                fields.first_name,
                fields.last_name,
                fields.status.extendField({labelType: true}),
                fields.functions,
            ]
        },
        totalRows() {
            return this.selectedEntity.type === 'department'
                ? this.departmentUsers.total ?? 1
                : this.functionUsers.total ?? 1
        }
    },
    created() {
        const params = this.computeParams();

        if (this.selectedEntity.type === 'department') {
            this.getDepartmentUsers(params)
                .catch(() => {
                    this.$notify({
                        icon: 'error',
                        title: this.$t('ADMIN_USERS_MODAL.DEPARTMENTS.ERRORS.LIST'),
                    });
                })
        } else {
            this.getFunctionUsers(params)
                .catch(() => {
                    this.$notify({
                        icon: 'error',
                        title: this.$t('ADMIN_USERS_MODAL.FUNCTIONS.ERRORS.LIST'),
                    });
                });
        }
    },
    watch: {
        currentPage: function (val, oldVal) {
            if (val !== oldVal) {
                const params = this.computeParams(val);

                if (this.selectedEntity.type === 'department') {
                    this.getDepartmentUsers(params)
                        .catch(() => {
                            this.$notify({
                                icon: 'error',
                                title: this.$t('ADMIN_USERS_MODAL.DEPARTMENTS.ERRORS.LIST'),
                            });
                        });
                } else {
                    this.getFunctionUsers(params)
                        .catch(() => {
                            this.$notify({
                                icon: 'error',
                                title: this.$t('ADMIN_USERS_MODAL.FUNCTIONS.ERRORS.LIST'),
                            });
                        });
                }
            }
        },
        searchByNameTerm: function (val, oldVal) {
            if (val !== oldVal) {
                const params = this.computeParams();

                if (this.selectedEntity.type === 'department') {
                    this.getDepartmentUsers(params)
                        .catch(() => {
                            this.$notify({
                                icon: 'error',
                                title: this.$t('ADMIN_USERS_MODAL.DEPARTMENTS.ERRORS.LIST'),
                            });
                        });
                } else {
                    this.getFunctionUsers(params)
                        .catch(() => {
                            this.$notify({
                                icon: 'error',
                                title: this.$t('ADMIN_USERS_MODAL.FUNCTIONS.ERRORS.LIST'),
                            });
                        });
                }


            }
        }
    },
    methods: {
        ...mapActions({
            getDepartmentUsers: `administrator/${FETCH_DEPARTMENT_USERS}`,
            getFunctionUsers: 'administrator/getFunctionUsers'
        }),
        cancel() {
            this.$emit('cancelModal');
        },
        onRowSelected(data) {
            this.$emit('cancelModal');
            this.$router.push({
                path: 'institution',
                query: {...this.$route.query, tab: 'users', user: data[0].user_id}
            })
        },
        computeParams(currentPage) {
            const actualCurrentPage = (currentPage ? currentPage : this.currentPage)
            const skip = (actualCurrentPage * this.perPage) - this.perPage;
            const limit = this.perPage;

            let params = {
                orgId: this.institution.id,
                skip,
                limit,
                currentPage: this.currentPage,
                searchByNameTerm: this.searchByNameTerm
            };

            if (this.selectedEntity.type === 'department') {
                params.departmentName = this.selectedEntity.data.name;
            } else {
                params.functionName = this.selectedEntity.data.name;
            }

            if (this.selectedEntity.type === 'function') {
                this.selectableRow = true;
            }

            return params;
        }
    },
}
</script>
