<template>
  <div class="list-box-wrapper">
    <div class="list-box-item">
      <div class="search-box">
        <input
            v-model="searchSource"
            type="text"
            :placeholder="$t('GENERAL.DUAL_LIST_BOX.SEARCH_PLACEHOLDER')"
        />
        <div
          v-if="searchSource"
          class="clear-search"
          title="Clear Search"
          @click=" searchSource='' "
        >&times;</div>
      </div>
      <b-list-group class="list-box">
        <b-list-group-item
            v-for="(item,key) in sourceList"
            v-bind:key="key"
            :class="'list-item'+ (item.selected ? ' active':'')"
            @click="selectSource(searchSource ? item.inx : key)"
        >
            {{item[label in item ? label : 'label']}}
        </b-list-group-item>
        <b-list-group-item
            v-if="sourceListFilter"
            class="list-item"
        >
            {{ $t('GENERAL.DUAL_LIST_BOX.SEARCH_RESULTS')}}
        </b-list-group-item>
    </b-list-group>
    </div>
    <div class="actions">
      <div class="btn-action primary" @click="moveDestination">
        <span class="svg-icon svg-icon svg-icon-xl ">
            <inline-svg
                class="svg-icon"
                :src="angleRight"
            />
        </span>
      </div>
      <div class="btn-action" @click="moveAllDestination">
          <span class="svg-icon svg-icon svg-icon-xl">
            <inline-svg
                class="svg-icon"
                :src="angleDoubleRight"
            />
        </span>
      </div>
      <div class="btn-action" @click="moveSource">
          <span class="svg-icon svg-icon svg-icon-xl">
            <inline-svg
                class="svg-icon"
                :src="angleLeft"
            />
         </span>
      </div>
      <div class="btn-action" @click="moveAllSource">
          <span class="svg-icon svg-icon svg-icon-xl">
            <inline-svg
                class="svg-icon"
                :src="angleDoubleLeft"
            />
         </span>
      </div>
    </div>
    <div class="list-box-item">
      <div class="search-box">
        <input
            v-model="searchDestination"
            type="text"
            :placeholder="$t('GENERAL.DUAL_LIST_BOX.SEARCH_PLACEHOLDER')"
        />
        <div
          v-if="searchDestination"
          class="clear-search"
          title="Clear Search"
          @click=" searchDestination='' "
        >&times;</div>
      </div>
      <b-list-group class="list-box">
        <b-list-group-item
            v-for="(item,key) in destinationList"
            v-bind:key="key"
            :class="'list-item'+ (item.selected ? ' active':'')"
            @click="selectDestination(searchDestination ? item.inx : key)"
        >
            {{item[label in item ? label : 'label']}}
        </b-list-group-item>
        <b-list-group-item
            v-if="destinationListFilter"
            class="list-item"
        >
            {{ $t('GENERAL.DUAL_LIST_BOX.SEARCH_RESULTS')}}
        </b-list-group-item>
    </b-list-group>
    </div>
  </div>
</template>

<script>
// Based on https://www.npmjs.com/package/dual-listbox-vue

import angleRight from "./assets/angle-right-solid.svg";
import angleLeft from "./assets/angle-left-solid.svg";
import angleDoubleLeft from "./assets/angle-double-left-solid.svg";
import angleDoubleRight from "./assets/angle-double-right-solid.svg";

export default {
    name: 'StDualListBox',
    props: {
        source: Array,
        destination: Array,
        label: String
    },
    data: function() {
        return {
            angleRight,
            angleLeft,
            angleDoubleLeft,
            angleDoubleRight,
            searchSource: '',
            searchDestination: '',
        };
    },
    computed: {
        sourceList() {
            return this.source.map((item,inx) => ({inx,...item})).filter(item => item[this.label in item ? this.label : 'label'].toLowerCase().includes(this.searchSource.toLowerCase()));
        },
        sourceListFilter() {
            return this.source.filter(item => item[this.label in item ? this.label : 'label'].toLowerCase().includes(this.searchSource.toLowerCase())).length == 0 && this.source.length;
        },
        destinationList() {
            return this.destination.map((item,inx) => ({inx,...item})).filter(item => item[this.label in item ? this.label : 'label'].toLowerCase().includes(this.searchDestination.toLowerCase()));
        },
        destinationListFilter() {
            return this.destination.filter(item => item[this.label in item ? this.label : 'label'].toLowerCase().includes(this.searchDestination.toLowerCase())).length == 0 && this.destination.length;
        }
    },
    methods: {
        moveDestination: function() {
            let selected = this.source.filter(f => f.selected);
            if (!selected.length) return;
            selected = selected.map(item => ({
                ...item,
                selected: false
            }));
            let destination = [...selected, ...this.destination];
            let source = this.source.filter(f => !f.selected);
            this.searchSource = "";
            this.searchDestination = "";
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        moveSource: function() {
            let selected = this.destination.filter(f => f.selected);
            if (!selected.length) return;
            selected = selected.map(item => ({
                ...item,
                selected: false
            }));
            let source = [...selected, ...this.source];
            let destination = this.destination.filter(f => !f.selected);
            this.searchSource = "";
            this.searchDestination = "";
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        moveAllDestination: function() {
            let destination = [
                ...this.source.map(item => ({ ...item, selected: false })),
                ...this.destination
            ];
            let source = [];
            this.searchSource = "";
            this.searchDestination = "";
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        moveAllSource: function() {
            let source = [
                ...this.destination.map(item => ({ ...item, selected: false })),
                ...this.source
            ];
            let destination = [];
            this.searchSource = "";
            this.searchDestination = "";
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        selectDestination: function(key) {
            let source = this.source;
            let destination = this.destination.map((i, k) => {
                if (k === key) {
                i.selected = !i.selected;
                }
                return i;
            });
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        selectSource: function(key) {
            let destination = this.destination;
            let source = this.source.map((i, k) => {
                if (k === key) {
                i.selected = !i.selected;
                }
                return i;
            });
            this.$emit("onChangeList", {
                source,
                destination
            });
        },
        selectAllSource: function() {
            let source = this.source.map(item => ({ ...item, selected: true }));
            this.$emit("onChangeList", {
                source,
                destination: this.destination
            });
        },
        deselectAllSource: function() {
            let source = this.source.map(item => ({ ...item, selected: false }));
            this.$emit("onChangeList", {
                source,
                destination: this.destination
            });
        },
        selectAllDestination: function() {
            let destination = this.destination.map(item => ({
                ...item,
                selected: true
            }));
            this.$emit("onChangeList", {
                source: this.source,
                destination
            });
        },
        deselectAllDestination: function() {
            let destination = this.destination.map(item => ({
                ...item,
                selected: false
            }));
            this.$emit("onChangeList", {
                source: this.source,
                destination
            });
        }
    }
};
</script>
