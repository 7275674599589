<template>
  <st-page :showHeader="false">
    <loader v-if="isLoading" />
    <decision-purpose-list-filter ref="decisionPurposeListFilter">
      <template #toolbar>
        <decision-purpose-list-toolbar />
      </template>
      <decision-purpose-list-table @update="updateList"/>
    </decision-purpose-list-filter>
  </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import DecisionPurposeListToolbar from "@/modules/decision-purpose/components/DecisionPurposeListToolbar.vue";
import DecisionPurposeListTable from "@/modules/decision-purpose/components/DecisionPurposeListTable.vue";
import DecisionPurposeListFilter from "@/modules/decision-purpose/components/DecisionPurposeListFilter.vue";

export default {
  name: "DecisionPurposeList",
  components: {
      DecisionPurposeListToolbar,
      DecisionPurposeListTable,
      DecisionPurposeListFilter
  },
  computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['decisionPurpose/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
    methods: {
      updateList() {
        this.$refs.decisionPurposeListFilter.refresh();
      },
    },
};
</script>
