<template>
    <div>
        <Loader v-if="loading['admin/resp/getPermissionsByOrgScope']"/>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <div v-if="editMode">
                        <label class="font-size-h6 mb-2 mr-3">
                            {{ $t('ORG_RESPONSIBILITIES.FORM.SELECTED_RESPONSIBILITY') }}
                        </label>
                        <span class="font-size-h6 font-weight-bold">
                            {{selectedResponsibility.responsibility_name}}
                        </span>
                    </div>
                    <div v-else>
                        <label>
                            {{fields.responsibilities.label}}
                        </label>
                        <b-form-select
                            :name="fields.responsibilities.name"
                            v-model="model[fields.responsibilities.name]"
                            :options="responsibilitiesOptions"
                        >
                        </b-form-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="form-group">
                    <label>
                    {{ $t('ORG_RESPONSIBILITIES.FORM.PERMISSIONS') }}
                    </label>
                    <st-dual-list-box
                        :source="source"
                        :destination="destination"
                        label="permission_name"
                        @onChangeList="onChangeList"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ResponsibilityModel } from '@/modules/administrator/models/responsibility-model';
import StDualListBox from '@/shared/components/dual-listbox/StDualListBox';

const { fields } = ResponsibilityModel;
const formSchema = new FormSchema([
    fields.responsibilities,
])
export default {
    name: 'ResponsibilityForm',
    components: { StDualListBox },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        selectedInstitution: {
            type: Object,
            required: true
        },
        selectedResponsibility: {
            type: Object,
            default: () => ({ }),
        },
        isDestinationListNotEmpty: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: formSchema.initialValues({}),
            fields,
            source: [],
            destination: [],
        }
    },
    computed: {
        ...mapGetters({
            permissions: 'administrator/respForm/permissionsByOrgScope',
            responsabilityPermissions: 'administrator/respForm/permissionsByResponsibility',
            orgResponsibilities: 'administrator/respList/rows',
            loading: 'shared/loading',
        }),
        responsibilitiesOptions() {
            return this.fields.responsibilities.options.filter(el=>
                !this.orgResponsibilities.some((item) => item.responsibility_id === el.value));
        },
    },
    watch: {
        'model.responsibilities': {
            deep: true,
            immediate: true,
            handler(responsibilityId) {
                if (responsibilityId) {
                    this.$emit('responsibilitySelected');
                }
            },
        },
        destination(values) {
            this.$emit('update:isDestinationListNotEmpty', !!values.length);
        }
    },
    created() {
        this.fields.responsibilities.updateOptions();
        this.doFetchPermissionsByOrgScope(this.selectedInstitution.permissions)
        .then(() => {
            this.source = this.permissions;
            if (this.editMode) {
                this.destination = this.source.filter(el=> this.selectedResponsibility.permissions.includes(el.name));
                this.source = this.source.filter(el=> !this.selectedResponsibility.permissions.includes(el.name));
            }
        })
    },
    methods: {
        ...mapActions({
            doAddResponsibility: 'administrator/respForm/addPermissionsToResponsibility',
            doUpdateResponsability: 'administrator/respForm/updatePermissionsToResponsibility',
            doFetchPermissionsByOrgScope: 'administrator/respForm/fetchPermissionsByOrgScope',
            doFetchPermissionsByResponsibility: 'administrator/respForm/fetchPermissionsByResponsibility',
        }),
        show() {
            this.$refs['st-permissions-modal'].show();
        },
        hide() {
            this.doClear();
            this.$emit('cancelForm');
        },
        doClear() {
             this.source.forEach(element => {
                element.selected = false;
            });
            this.destination = [];
            this.source = this.permissions;
            this.model.responsibilities = '';
        },
        addResposibility() {
            const selectedResponsibility = this.fields.responsibilities.options.find(resp => resp.value === this.model.responsibilities);
            const permissions = this.destination.map(el => el.name);
            const payload = {
                responsibility_id: selectedResponsibility.value,
	            responsibility_name: selectedResponsibility.text,
                organisation_id: this.selectedInstitution.id,
                organisation_name: this.selectedInstitution.name,
                permissions
            }

            this.doAddResponsibility(payload)
            .then(() => {
                this.$emit('submit');
                this.hide();
            })
        },
        updateResposibility () {
            const permissions = this.destination.map(el => el.name);
            this.doUpdateResponsability({id: this.selectedResponsibility.id, permissions})
            .then(() => {
                this.$emit('submit');
                this.hide();
            })
        },
        onChangeList: function ({ source, destination }) {
            this.source = source;
            this.destination = destination;
        },
        manageResponsibility() {
            if (this.editMode) {
                this.updateResposibility();
            } else {
                this.addResposibility();
            }
        }
    },
}
</script>
